import types from '../types';

const initialState = {
  searchOptions: [],
  selectedCategory: null,
  searchValue: '',
  filters: null,
  searchData: null,
  totalPages: null,
  totalRecords: null,
  loadingList: false,
  possibleCategories: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SEARCH_FILTERS:
      return {
        ...state,
        filters: action.$payload,
      };
    case types.SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.$payload.value,
      };
    case types.SET_OPTIONS_QUERY:
      return {
        ...state,
        optionQuery: [...action.$payload],
      };
    case types.GET_SEARCH:
      return {
        ...state,
        loadingList: true,
      };
    case types.DEL_SEARCH:
      return {
        ...initialState,
      };
    case types.SET_LOADING_SEARCH_FLAG:
      return {
        ...state,
        loadingList: action.$payload.value,
      };
    case types.SET_SELECTED_SEARCH_CATEGORY:
      return {
        ...state,
        selectedCategory: action.$payload.value,
      };
    case types.SET_SEARCH:
      // eslint-disable-next-line no-case-declarations
      const catalogueList = action.$payload.products.data;
      return {
        ...state,
        searchData: action.$payload.keepPreviousValues
          ? [...state.list ? state.list : [], ...catalogueList]
          : [...catalogueList],
        totalPages: action.$payload.products.meta.last_page,
        totalRecords: action.$payload.products.meta.total,
        loadingList: false,
        possibleCategories: action.$payload.subcategories,
      };
    case types.SET_SEARCH_OPTIONS:
      return {
        ...state,
        searchOptions: [...action.$payload],
      };
    default:
      return state;
  }
};

export default reducer;
