/* eslint-disable */
import * as R from 'ramda';
import fp from 'lodash/fp';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { isNil } from 'lodash';
import { Object } from 'ol';

export const path = (data) => (p) => R.flip(R.path)(data, p.split('.'));

export const getDataFromLocalStorage = (key) => {
  const item = localStorage.getItem(key);
  return item === '' ? item : JSON.parse(item) || [];
};

export const dimensionsForFavorites = ({ width, length, height }) => {
  const widthWithLabel = !isNil(width) ? `ш ${width}см` : '';
  const lengthWithLabel = !isNil(length) ? `д ${length}см` : '';
  const heightWithLabel = !isNil(height) ? `в ${height}см` : '';
  const notDimensions = isNil(width) && isNil(length) && isNil(length) ? '—' : '';
  return `Размер: ${widthWithLabel} ${lengthWithLabel} ${heightWithLabel}${notDimensions}`;
};

export const addSignMore = (value, maxValue) => {
  return (!isNil(value) && value >= 1000000) ? `<${maxValue - 1}` : value;
};

export const getProductOption = (options, desiredOption) => options?.find((el) => el.name.toLowerCase() === desiredOption)?.value;

export const parseQueryDataFromCatalogue = (query) => {
  return ({
    page: query.page ? parseInt(query.page, 10) : 1,
    filters: query.filters || {},
    sort: query.sort || null,
    selectedCategory: !isNil(query.selectedCategory) ? parseInt(query.selectedCategory, 10) : null,
    selectedSubCategory: !isNil(query.selectedSubCategory) ? parseInt(query.selectedSubCategory, 10) : null,
  });
};

export const parseQueryDataFromSearch = (query) => {
  return ({
    page: query.page ? parseInt(query.page, 10) : 1,
    filters: query.filters || {},
    sort: query.sort || null,
    searchValue: query.searchValue || '',
    selectedCategory: !isNil(query.selectedCategory) ? parseInt(query.selectedCategory, 10) : null,
  });
};

export const isURL = (str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$','i');
  return pattern.test(str);
}

export const HELPERS = {
  parseErrors: (errors) => {
    if (errors && errors.errors && errors.errors.length !== undefined) {
      return fp.map(
        (v) => v.defaultMessage,
      )(errors.errors).join(', ');
    }
    return errors && (errors.error || errors.detail);
  },
  isPhone: (data) => (/^((8|\+7)[-]?)?(\(?\d{3}\)?[-]?)?[\d-]{10,10}$/).test(data),

  getOnlyNumsPhone: (value) => value.replace('+7', '').replace(/[^\d]/g, ''),

  onlyNums: (value) => {
    if ((/^\d+$/).test(value)) return value;
    return false;
  },

  formatCard: (value) => {
    const onlyNums = HELPERS.getOnlyNumsPhone(value);
    if (onlyNums.length === 0) return '';
    if (onlyNums.length <= 3) return `${onlyNums.slice(0, 3)}`;
    if (onlyNums.length <= 6) return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 6)}`;
    if (onlyNums.length <= 9) return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 6)} ${onlyNums.slice(6, 9)}`;
    if (onlyNums.length <= 13) return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 6)} ${onlyNums.slice(6, 9)} ${onlyNums.slice(9, 13)}`;
    return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 6)} ${onlyNums.slice(6, 9)} ${onlyNums.slice(9, 13)}`;
  },

  formatPhone: (value, isNeedBracket = true) => {
    if (!value) {
      return value;
    }
    const prefix = '+7';
    const onlyNums = HELPERS.getOnlyNumsPhone(value);
    if (onlyNums.length === 0) {
      return isNeedBracket ? onlyNums : prefix;
    }
    if (onlyNums.length <= 3) {
      return isNeedBracket
        ? `${prefix}(${onlyNums}`
        : `${prefix}-${onlyNums}`;
    }
    if (onlyNums.length <= 6) {
      return isNeedBracket
        ? `${prefix}(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 7)}`
        : `${prefix}-${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 7)}`;
    }
    if (onlyNums.length <= 8) {
      return isNeedBracket
        ? `${prefix}(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 8)}`
        : `${prefix}-${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 8)}`;
    }
    return isNeedBracket
      ? `${prefix}(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 8)}-${onlyNums.slice(8, 10)}`
      : `${prefix}-${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 8)}-${onlyNums.slice(8, 10)}`;
  },
  formatDate: (value) => {
    const onlyNums = HELPERS.getOnlyNumsPhone(value);
    // console.log(moment("21.12.2020", 'DD.MM.YYYY').format('L'))
    if (onlyNums.length === 0) return '';
    if (onlyNums.length < 3) {
      if (parseInt(onlyNums, 10) <= 31) {
        return `${onlyNums}`;
      }
      return onlyNums.slice(0, -1);
    }
    if (onlyNums.length < 5) {
      if (parseInt(onlyNums.slice(2, 4), 10) <= 12) {
        return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 4)}`;
      }
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, -1)}`;
    }
    if (onlyNums.length < 10) {
      if (parseInt(onlyNums.slice(4, 8), 10) <= moment().format('YYYY')) {
        return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 4)}.${onlyNums.slice(4, 8)}`;
      }
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 4)}.${onlyNums.slice(4, -1)}`;
    }
  },
};

export const changeCartFromPreview = (list, item, count) => {
  const copy = fp.cloneDeep(list);
  console.log(copy);
  const findIndex = copy.findIndex((v) => v.id === item.id);
  console.log(findIndex);
  if (findIndex >= 0) {
    copy[findIndex] = fp.cloneDeep({
      ...item,
      count,
    });
  } else {
    copy.push({
      ...item,
      count,
    });
  }
  return copy;
};

export const changeCart = (list, item, type) => {
  const copy = fp.cloneDeep(list);
  const findIndex = copy.findIndex((v) => v.id === item.id);
  if (type === 'add') {
    if (findIndex >= 0) {
      copy[findIndex] = fp.cloneDeep({
        ...item,
        count: copy[findIndex].count + 1,
      });
    } else {
      copy.push({
        ...item,
        count: 1,
      });
    }
  } else if (copy[findIndex].count > 1) {
    copy[findIndex] = {
      ...copy[findIndex],
      count: copy[findIndex].count - 1,
    };
  } else {
    copy.splice(findIndex, 1);
  }
  return copy;
};

export const parseMobileFilters = (filters, value) => {
  return (
    filters ? [{
      name: 'Метки',
      query_name: 'labels',
      widget: 'CheckboxSelectMultiple',
      values_list: filters.map((el) => ({
        ...el,
        value: el.id,
        formatted: el.title,
      })),
      value: value ? [value] : [],
    }] : []
  );
};

export const calculateCart = (list, name) => (list.length === 0
  ? 0
  : fp.pipe(
    fp.map((item) => (item[name] ? parseFloat(item[name]) * item.count : 0)),
    fp.reduce((sum, current) => sum + current, 0),
  )(list));

export default {
  path,
};

export function generateGuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return `${s4() + s4()}-${s4()}-${s4()}-${
    s4()}-${s4()}${s4()}`;
}

export const getReturnUrl = () => {
  return window.location.href.split('#')[0];
};

// export const calcSliderElements = () =>
// (window.innerWidth < 1280 ? Math.trunc(window.innerWidth / 400) : Math.trunc(1280 / 376));

export function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'none' }}
      onClick={onClick}
    />
  );
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const getSuccessPaymentUrl = () => {
  return `${getReturnUrl()}#/successPayment`;
};

export const getFailPaymentUrl = () => {
  return `${getReturnUrl()}#/failPayment`;
};

export const cutString = (text) => {
  let result = text.trim();
  if (result.length <= 150) return result;

  result = result.slice(0, 150);

  return `${result.trim()}...`;
};

export const distanceConverter = (distance) => {
  if (distance >= 1000) {
    return `${distance / 1000} км`;
  }
  return `${distance} м`;
};

export const pluralizerQuantityDishes = (value) => {
  if (value % 10 === 1) return `${value} блюдо`;
  // if (value > 1 && value < 5) return `${value} блюда`;
  if (value % 10 > 1 && value % 10 < 5) return `${value} блюда`;
  return `${value} блюд`;
};

export const checkInTheBasket = (products, eatList) => {
  return products?.map((el) => ({
    ...el,
    // quantityInBasket: eatList?.find(item => item.product.id === el.id)?.quantity || 0,
    quantityInBasket: eatList
      ?.filter((item) => item.product.id === el.id)
      .reduce((sum, current) => sum + current?.quantity, 0) || 0,
    idInBasket: eatList?.find((item) => item.product.id === el.id)?.id,
  })) || [];
};

export const detectOS = () => {
  const platform = navigator.platform.toLowerCase();
  const iosPlatforms = ['iphone', 'ipad', 'ipod', 'ipod touch'];

  if (platform.includes('mac')) return 'MacOS';
  if (iosPlatforms.includes(platform)) return 'iOS';
  if (platform.includes('win')) return 'Windows';
  if (/android/.test(navigator.userAgent.toLowerCase())) return 'Android';
  if (/linux/.test(platform)) return 'Linux';

  return 'unknown';
};
