import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { profileSelector } from '../index';
import { updateOpeningHours, updateShopName, updateUserData } from '../../../actions/user';
import { Address } from './address';
import { ContactDetails } from './contactDetails';
import { getInitialUserData } from './helper';
import { ShopDetails } from './shopDetails';
import './index.scss';

export const UserData = () => {
  const dsp = useDispatch();
  const user = useSelector(profileSelector);
  const initialValues = getInitialUserData(user || {});
  console.log('user', user);
  console.log('initialValues', initialValues);
  const onSubmit = (values) => {
    dsp(updateUserData({
      id: user.id,
      body: values,
    }));
  };

  const handleSubmitShopData = (values) => {
    if (values.shop_name) {
      dsp(updateShopName({ shop_name: values.shop_name }));
    }
    if (values.shop_opening_hours) {
      dsp(updateOpeningHours({ shop_opening_hours: values.shop_opening_hours }));
    }
  };

  const formValidate = (values) => {
    const errors = {};
    if (!values.shop_name) {
      errors.shop_name = 'Поле обязательно';
    }
    if (!values.shop_opening_hours) {
      errors.shop_opening_hours = 'Укажите график работы';
    }
    return errors;
  };

  return (
    <div className="UserData">
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        // validate={formValidate}
        render={({ handleSubmit, pristine }) => {
          // resetForm = form.reset;
          return (
            <form onSubmit={handleSubmit}>
              <div className="upPart">
                <div className="leftPart">
                  <div className="UserData_title">
                    Контактные данные
                  </div>
                  <ContactDetails />
                </div>
                <div>
                  <div className="UserData_title">
                    Адрес
                  </div>
                  <Address />
                </div>
              </div>
              <div className="saveContainer">
                <button
                  className="save"
                  disabled={pristine}
                  type="submit"
                >
                  Сохранить
                </button>
              </div>
            </form>
          );
        }}
      />
      <Form
        onSubmit={handleSubmitShopData}
        initialValues={{
          shop_name: initialValues.shop_name,
          shop_opening_hours: initialValues.shop_opening_hours,
        }}
        validate={formValidate}
        render={({ handleSubmit, form, values, errors }) => {
          return (
            <form className="shopDataContainer" onSubmit={handleSubmit}>
              <ShopDetails
                fieldChange={form.change}
                values={values}
                errors={errors}
              />
              <div className="saveContainer">
                <button
                  className="save"
                  // disabled={pristine}
                  type="submit"
                >
                  Сохранить
                </button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};
