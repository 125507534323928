import { createAction } from './common';
import types from '../types';

export const getSearchOptions = createAction(types.GET_SEARCH_OPTIONS);
export const setSearchOptions = createAction(types.SET_SEARCH_OPTIONS);
export const getOptionsQuery = createAction(types.SET_OPTIONS_QUERY);

export const getSearch = createAction(types.GET_SEARCH);
export const setSearch = createAction(types.SET_SEARCH);
export const delSearch = createAction(types.DEL_SEARCH);
export const setLoadingSearchFlag = createAction(types.SET_LOADING_SEARCH_FLAG);
export const setSelectedSearchCategory = createAction(types.SET_SELECTED_SEARCH_CATEGORY);
export const setSearchValue = createAction(types.SET_SEARCH_VALUE);
export const setSearchFilters = createAction(types.SET_SEARCH_FILTERS);
