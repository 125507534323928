import React, { useEffect, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '../../../../components/simpleComponents/textField';
import { PhotoEditor } from '../../../../components/photoEditor';
import {
  addCommonBanner,
  clearCommonBanners,
  getCommonBanners,
  removeCommonBanner,
  setAddedFlagCommonBanner,
} from '../../../../actions/banner';

export const CommonBanner = () => {
  const [linkForAdd, setLinkForAdd] = useState('');
  const [bannerImageForAdd, setBannerImageForAdd] = useState(null);
  const [bannerMobileImageForAdd, setBannerMobileImageForAdd] = useState(null);

  const [bannerIdForDelete, setBannerIdForDelete] = useState(null);
  const dsp = useDispatch();
  const { added } = useSelector((state) => state.banner);

  useEffect(() => {
    if (added) {
      dsp(setAddedFlagCommonBanner({ value: false }));
      setBannerImageForAdd(null);
      setLinkForAdd('');
      setBannerMobileImageForAdd(null);
    }
  }, [added, dsp]);

  useEffect(() => {
    dsp(getCommonBanners());
    return () => dsp(clearCommonBanners());
  }, [dsp]);

  const addBanner = () => {
    dsp(addCommonBanner({
      url: bannerImageForAdd,
      link: linkForAdd,
      mobileUrl: bannerMobileImageForAdd,
    }));
  };

  const deleteBanner = () => {
    dsp(removeCommonBanner({
      id: bannerIdForDelete,
    }));
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        Общий баннер каталога
      </AccordionSummary>
      <AccordionDetails>
        <div className="CommonBanner">
          <div>Добавить баннер</div>
          <TextField
            placeholder="Ссылка баннера"
            value={linkForAdd}
            error={!linkForAdd ? 'Обязательное поле' : undefined}
            onChange={(e) => {
              setLinkForAdd(e.target.value);
            }}
          />
          <PhotoEditor
            needWidth={1697}
            needHeight={404}
            label="Картинка баннера"
            onChange={setBannerImageForAdd}
            value={bannerImageForAdd}
          />
          <PhotoEditor
            needWidth={845}
            needHeight={484}
            label="Картинка баннера для мобильных устройств"
            onChange={setBannerMobileImageForAdd}
            value={bannerMobileImageForAdd}
          />
          <button
            onClick={addBanner}
            disabled={!linkForAdd || !bannerImageForAdd || !bannerMobileImageForAdd}
          >
            Добавить баннер
          </button>
          <div>Редактировать баннер</div>
          <div>Удалить баннер</div>
          <TextField
            placeholder="id баннера"
            value={bannerIdForDelete}
            error={!bannerIdForDelete ? 'Обязательное поле' : undefined}
            onChange={(e) => {
              setBannerIdForDelete(e.target.value);
            }}
          />
          <button
            onClick={deleteBanner}
            disabled={!bannerIdForDelete}
          >
            Удалить баннер
          </button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
