import { put } from 'redux-saga/effects';
import API from '../api';
import {
  setLoadingSearchFlag,
  setSearch, setSearchFilters,
  setSearchOptions,
} from '../actions/search';
import { history } from '../store';
import { routes } from '../routes';
import { prepareFilters } from './catalogue';

export function* getSearchSaga(action) {
  try {
    const {
      keepPreviousValues, saveFilters = false, selectedCategory, query, value,
    } = action.$payload;
    console.log('selectedCategory', selectedCategory);
    const json = yield API.rest.search.create({
      value,
      category_id: selectedCategory || null,
      ...query || {},
    });
    yield put(setSearch({ ...json, keepPreviousValues }));
    history.push(routes.searchPage('', {
      ...query,
      searchValue: value,
      ...selectedCategory ? { selectedCategory } : {},
    }));
    if (saveFilters) {
      yield put(setSearchFilters(prepareFilters(json)));
    } // сохраняются не значения фильтров, а сами возможные фильтры
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put(setLoadingSearchFlag({ value: false }));
  }
}

export function* getSearchOptions(action) {
  try {
    const json = yield API.rest.searchOptions.create({ value: action.$payload.value });
    yield put(setSearchOptions(json));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}
