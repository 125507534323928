import { put, call, select } from 'redux-saga/effects';
import API from '../api';
import { setAuthData, setSendPhoneLogin, clearAuthData } from '../actions/auth';
import {
  clearUserData,
  setCategories,
  setChangingUserRole,
  setColors,
  setIsLoadingAuth, setPartOfUserData,
  setRoles,
  setRoleUser,
  setUser,
  setUsers,
} from '../actions/user';
import { history } from '../store';
import { routes } from '../routes';
import { deleteAllCookies } from '../helpers/http';
import { syncFavoritesSaga } from './favorites';
import { changeBasketListSaga } from './basket';
import { setNotification } from '../actions/notification';
import { profileSelector } from '../pages/profile';
import { setBasketList } from '../actions/basket';
import { setFavorites } from '../actions/favorites';
// import { syncFavorites } from '../actions/favorites';
// import { changeBasketList } from '../actions/basket';
// import { setNotification } from "../actions/notifications";
// import { getUserSaga } from "./user";

export function storeTokens({ token } = {}) {
  localStorage.setItem('accessToken', token || '');
}

export function* sendPhoneSaga(action) {
  try {
    yield API.rest.login.getCsrfCookie();
    yield API.rest.login.sendPhone({ phone: action.$payload.phone });
    yield put(setSendPhoneLogin());
    action.$payload?.onFinish?.();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    if (err?.detail.errors) {
      yield put(setNotification({
        type: 'error',
        message: Object.values(err?.detail.errors).join(', '),
      }));
    }
    yield put(setNotification({
      type: 'error',
      message: 'Ошибка подтверждения телефона',
    }));
    yield action.$payload?.onFail?.();
  }
}

export function* sendCodeSaga(action) {
  try {
    yield put(setIsLoadingAuth({ value: true }));
    const json = yield API.rest.login.sendCode({
      code: action.$payload.code,
      phone: action.$payload.phone,
      device: action.$payload.device,
    });
    storeTokens(json);
    yield put(setAuthData({
      accessToken: json.token,
    }));
    yield put(setUser(json.user));
    yield call(syncFavoritesSaga);
    yield call(changeBasketListSaga);
    yield put(setIsLoadingAuth({ value: false }));
    yield action.$payload?.onFinish?.();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    // yield put( setNotification({
    //     type: 'error',
    //     title: err?.error ?? 'Ошибка',
    //     message: err?.detail ?? 'Ошибка при отправке кода!'
    // }));
    yield put(setIsLoadingAuth({ value: false }));
    yield action.$payload?.onFail?.();
  }
}

export function* getCurrentUserSaga() {
  try {
    // const accessToken = localStorage.getItem('accessToken');
    // if (accessToken) {
    //
    // } else {
    //   yield call(logoutSaga);
    // }
    const json = yield API.rest.auth.getProfile();
    yield put(setUser(json));
    // action.$payload.onFinish();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield call(logoutSaga);
    // action.$payload.onFinish();
  }
}

// export function* updateCurrentUserSaga( action ) {
//     try {
//         const json = yield API.rest.guests.changeGuest(action.$payload.data);
//         yield put( setCurrentUser(json));
//         yield console.log('[UPDATE GUEST SAGA] json', json);
//         action.$payload.onFinish();
//     } catch ( err ) {
//         console.log(err);
//     }
// }
//
// export function* updateCurrentUserMessagesSaga( action ) {
//     try {
//         const json = yield API.rest.guests.changeMessageDistribution(action.$payload.data);
//         yield put( setCurrentUser(json));
//     } catch ( err ) {
//         console.log(err);
//     }
// }

export function* addCategoriesSaga(action) {
  try {
    yield API.rest.category.create(action?.$payload.values);
    yield call(getCategoriesSaga);
    action?.$payload?.onFinish();
    yield put(setNotification({
      type: 'success',
      message: 'Категория добавлена',
    }));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(setNotification({
      type: 'error',
      message: 'Ошибка добавления категории',
    }));
  }
}

export function* addSubCategoriesSaga(action) {
  try {
    yield API.rest.category.create(action?.$payload.values);
    yield call(getCategoriesSaga);
    action?.$payload?.onFinish();
    yield put(setNotification({
      type: 'success',
      message: 'Подкатегория добавлена',
    }));
  } catch (err) {
    yield put(setNotification({
      type: 'error',
      message: 'Ошибка добавления подкатегории',
    }));
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export function* deleteCategoriesSaga(action) {
  try {
    yield API.rest.category.remove(action?.$payload.value);
    yield call(getCategoriesSaga);
    action?.$payload?.onFinish();
    yield put(setNotification({
      type: 'success',
      message: 'Категория удалена',
    }));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(setNotification({
      type: 'error',
      message: 'Ошибка удаления категории',
    }));
  }
}

export function* deleteSubCategorySaga(action) {
  try {
    yield API.rest.category.remove(action?.$payload.id);
    yield call(getCategoriesSaga);
    action?.$payload?.onFinish();
    yield put(setNotification({
      type: 'success',
      message: 'Подкатегория удалена',
    }));
  } catch (err) {
    yield put(setNotification({
      type: 'error',
      message: 'Ошибка удаления подкатегории',
    }));
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export function* getCategoriesSaga() {
  try {
    const json = yield API.rest.category.list();
    yield put(setCategories(json));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export function* getColorsSaga() {
  try {
    const json = yield API.rest.color.list();
    yield put(setColors(json));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export function* addColorSaga(action) {
  try {
    yield API.rest.color.create(action?.$payload.value);
    yield call(getColorsSaga);
    yield put(setNotification({
      type: 'success',
      message: 'Цвет добавлен',
    }));
  } catch (err) {
    yield put(setNotification({
      type: 'error',
      message: 'Ошибка смены цвета',
    }));
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export function* delColorSaga(action) {
  try {
    yield API.rest.color.remove(action?.$payload.value);
    yield call(getColorsSaga);
    action?.$payload?.onFinish?.();
    yield put(setNotification({
      type: 'success',
      message: 'Цвет удален',
    }));
  } catch (err) {
    yield put(setNotification({
      type: 'error',
      message: 'Ошибка удаления цвета',
    }));
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export function* getRolesSaga() {
  try {
    const json = yield API.rest.role.list();
    yield put(setRoles(json));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export function* getUsersSaga(action) {
  try {
    const json = yield API.rest.user.list(action?.$payload);
    yield put(setUsers({
      list: json.data,
      page: json.current_page,
      total: json.total,
    }));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export function* changeUserRoleSaga(action) {
  try {
    const { id, role } = action.$payload;
    yield put(setChangingUserRole({ value: true }));
    yield API.rest.user.changeRole({ id, body: { role } });
    yield put(setRoleUser({ id, role }));
    yield put(setChangingUserRole({ value: false }));
    yield put(setNotification({
      type: 'success',
      message: 'Роль изменена',
    }));
  } catch (err) {
    yield put(setNotification({
      type: 'error',
      message: err?.detail?.message || 'Ошибка смены роли',
    }));
    yield put(setChangingUserRole({ value: false }));
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export function* updateShopNameSaga(action) {
  try {
    yield API.rest.auth.updateShopName({ body: action?.$payload });
    yield put(setPartOfUserData({
      shop_name: action?.$payload.shop_name,
    }));
    yield put(setNotification({
      type: 'success',
      message: 'Название магазина обновлено',
    }));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export function* updateOpeningHoursSaga(action) {
  try {
    yield API.rest.auth.updateOpeningHours({ body: action?.$payload });
    yield put(setPartOfUserData({
      shop_opening_hours: action?.$payload.shop_opening_hours,
    }));
    yield put(setNotification({
      type: 'success',
      message: 'Время работы магазина обновлено',
    }));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export function* updateUserSaga(action) {
  try {
    const user = yield select(profileSelector);
    const { role } = user || {};
    let json;
    if (role === 'user' || role === 'seller') {
      json = yield API.rest.auth.update(action.$payload);
    } else {
      json = yield API.rest.user.update(action.$payload);
    }
    yield put(setUser(json));
    yield put(setNotification({
      type: 'success',
      message: 'Вы успешно обновили свои данные!',
    }));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(setNotification({
      type: 'error',
      message: 'Что-то пошло не так при обновлении данных...',
    }));
  }
}

export function* logoutSaga(action) {
  try {
    history.push(routes.catalogue());
    yield put(clearAuthData());
    yield put(clearUserData());
    yield put(setBasketList([]));
    yield put(setFavorites({ data: [] }));
    localStorage.clear();
    deleteAllCookies();
    action?.$payload?.onFinish?.();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    // yield put( setNotification({
    //     type: 'error',
    //     title: 'Ошибка',
    //     message: 'Ошибка при выходе из профиля'
    // }));
    action?.$payload?.onFail?.();
  }
}
