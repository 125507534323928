import types from '../types';

const initialState = {
  user: null,
  categories: [],
  subcategories: [],
  colors: [],
  roles: [],
  users: {
    list: null,
    page: 1,
    total: null,
  },
  changingRole: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER:
      return {
        ...state,
        user: action.$payload,
      };
    case types.SET_PART_OF_USER_DATA:
      return {
        ...state,
        user: {
          ...state.user ? state.user : {},
          ...action.$payload,
        },
      };
    case types.SET_ROLES:
      return {
        ...state,
        roles: prepareRoles(action.$payload),
      };
    case types.SET_CHANGING_USER_ROLE:
      return {
        ...state,
        changingRole: action.$payload.value,
      };
    case types.CLEAR_USERS:
      return {
        ...state,
        users: {
          list: null,
          page: 1,
          total: null,
        },
      };
    case types.SET_USERS:
      return {
        ...state,
        users: {
          list: action.$payload.list,
          page: action.$payload.page,
          total: action.$payload.total,
        },
      };
    case types.SET_ROLE_USER:
      return {
        ...state,
        users: {
          list: state.users.list.map((el) => (action.$payload.id === el.id
            ? ({ ...el, role: action.$payload.role })
            : el)),
          page: state.users.page,
          total: state.users.total,
        },
      };
    case types.SET_CATEGORIES:
      return {
        ...state,
        categories: action.$payload,
        subcategories: action.$payload.reduce((acc, item) => [...acc, ...item.subcategories], []),
      };
    case types.CLEAR_CATEGORIES:
      return {
        ...state,
        categories: [],
        subcategories: [],
      };
    case types.CLEAR_ROLES:
      return {
        ...state,
        roles: [],
      };
    case types.SET_COLORS:
      return {
        ...state,
        colors: action.$payload,
      };
    case types.CLEAR_COLORS:
      return {
        ...state,
        colors: [],
      };
    case types.CLEAR_USER_DATA:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};

export default reducer;

const prepareRoles = (data) => data.map((el) => ({
  id: el.id,
  name: el.name,
}));
