import { Field } from 'react-final-form';
import React, { useEffect, useState } from 'react';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import validators from '../../../../helpers/form-validators/validators-lodash';
import { TextField } from '../../../../components/simpleComponents/textField';
import { SelectMui } from '../../../../components/simpleComponents/selectMui';
import './index.scss';

const dayAndNightOptions = [
  {
    label: 'Круглосуточно',
    value: 'dayAndNight',
  },
  {
    label: 'В указанные часы',
    value: 'openingHours',
  },
];

const initTimeFrom = new Date('2024-05-18T09:00');
const initTimeTo = new Date('2024-05-18T18:00');

const dateWithTimeToTime = (date) => new Date(date).toLocaleTimeString().split(':', 2).join(':');

export const ShopDetails = ({ isOrderFields, fieldChange, values, errors }) => {
  const [dayAndNightOrNot, setDayAndNightOrNot] = useState('openingHours');
  const [timeFrom, setTimeFrom] = useState(null);
  const [timeTo, setTimeTo] = useState(null);

  useEffect(() => {
    if (values?.shop_opening_hours === 'dayAndNight') {
      setDayAndNightOrNot('dayAndNight');
      fieldChange('shop_opening_hours', 'dayAndNight');
      return;
    }
    if (!values?.shop_opening_hours || values.shop_opening_hours === 'openingHours') {
      setDayAndNightOrNot('openingHours');
      setTimeFrom(null);
      setTimeTo(null);
      fieldChange('shop_opening_hours', undefined);
      return;
    }
    if (values?.shop_opening_hours) {
      const [from, to] = values.shop_opening_hours.split('-');
      const [fromHours, fromMinutes] = from.split(':');
      const [toHours, toMinutes] = to.split(':');
      const newDateFrom = new Date(initTimeFrom);
      newDateFrom.setMinutes(fromMinutes);
      newDateFrom.setHours(fromHours);
      setTimeFrom(newDateFrom);
      const newDateTo = new Date(initTimeTo);
      newDateTo.setMinutes(toMinutes);
      newDateTo.setHours(toHours);
      setTimeTo(newDateTo);
      setDayAndNightOrNot('openingHours');
    }
  }, [values?.shop_opening_hours]);

  useEffect(() => {
    if (dayAndNightOrNot === 'dayAndNight') {
      fieldChange('shop_opening_hours', 'dayAndNight');
      return;
    }
    if (timeFrom && timeTo) {
      fieldChange('shop_opening_hours', `${dateWithTimeToTime(timeFrom)}-${dateWithTimeToTime(timeTo)}`);
    }
  }, [timeFrom, timeTo, dayAndNightOrNot, fieldChange]);

  return (
    <div className="shopDetails">
      <div className="shopDetails_title">
        Данные магазина
      </div>
      {!isOrderFields && (
      <Field
        name="shop_name"
        validate={validators.required}
      >
        {({ input, meta }) => (
          <TextField
            {...input}
            meta={meta}
            placeholder="Название магазина"
          />
        )}
      </Field>
      )}
      <div>
        <div className="inputLabels">Выберите режим работы</div>
        <SelectMui
          className="select"
          value={dayAndNightOrNot}
          error={!dayAndNightOrNot}
          helperText={!dayAndNightOrNot ? 'Обязательное поле' : undefined}
          onChange={(e) => {
            setDayAndNightOrNot(e.target.value);
          }}
          options={dayAndNightOptions}
        />
      </div>
      {
        errors?.shop_opening_hours && (
          <div className="shopOpeningHoursError">{errors.shop_opening_hours}</div>
        )
      }
      {
        dayAndNightOrNot === 'openingHours' && (
        <div className="timesContainer">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="inputLabels">От</div>
            <TimePicker
              ampm={false}
              onChange={setTimeFrom}
              value={timeFrom}
              okLabel="Применить"
              cancelLabel="Отмена"
            />
            <div className="inputLabels">До</div>
            <TimePicker
              ampm={false}
              onChange={setTimeTo}
              value={timeTo}
              okLabel="Применить"
              cancelLabel="Отмена"
            />
          </MuiPickersUtilsProvider>
        </div>
        )
      }
    </div>
  );
};
