import { Field } from 'react-final-form';
// import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '../../../../components/simpleComponents/textField';
import validators from '../../../../helpers/form-validators/validators-lodash';
import { HELPERS } from '../../../../helpers';
import { sendCodeLogin, sendPhoneLogin } from '../../../../actions/auth';
import { MOBILE_BREAK_POINT } from '../../../../api/constants';

export const ContactDetails = ({ isAuthorized, isOrderFields = false, values }) => {
  const [codeConfirmationSended, setCodeConfirmationSended] = useState(false);
  const [msToResend, setMsToResend] = useState(60000);
  const [timerActive, setTimerActive] = React.useState(false);
  const dsp = useDispatch();
  // let interval;

  useEffect(() => {
    if (values?.code && values.code?.length === 4) {
      dsp(sendCodeLogin({
        code: values.code,
        phone: values.phone.replace(/[( )\\-]/g, ''),
        device: window.innerWidth > MOBILE_BREAK_POINT ? 'Web' : 'Mobile',
        // onFinish: onClose,
      }));
    }
  }, [dsp, values?.code, values?.phone]);

  useEffect(() => {
    let timerId;
    if (msToResend > 0 && timerActive) {
      timerId = setTimeout(setMsToResend, 1000, msToResend - 1000);
    } else {
      setTimerActive(false);
      setMsToResend(60000);
    }
    return () => clearTimeout(timerId);
  }, [msToResend, timerActive]);

  const onSendPhone = () => {
    setCodeConfirmationSended(true);
    setTimerActive(true);
    dsp(sendPhoneLogin({
      phone: values.phone.replace(/[( )\\-]/g, ''),
    }));
  };

  const isDisabled = msToResend > 0 && timerActive;

  return (
    <div className="ProfileAndOrderFields">
      <div className="fields">
        <Field
          name="surname"
          validate={validators.required}
        >
          {({ input, meta }) => (
            <TextField
              {...input}
              meta={meta}
              placeholder="Фамилия"
            />
          )}
        </Field>
        <Field
          name="name"
          validate={validators.required}
        >
          {({ input, meta }) => (
            <TextField
              {...input}
              meta={meta}
              placeholder="Имя"
            />
          )}
        </Field>
        <Field
          name="phone"
          validate={validators.is.phone}
        >
          {({ input, meta }) => (
            <TextField
              {...input}
              meta={meta}
              placeholder="Телефон"
              formatFunc={HELPERS.formatPhone}
            />
          )}
        </Field>
        {!isAuthorized && isOrderFields && (
          <button
            className="confirmPhone"
            type="button"
            disabled={validators.is.phone(values.phone) || isDisabled}
            onClick={onSendPhone}
          >
            {isDisabled ? `повторный звонок возможен через ${Math.floor(msToResend / 1000)} сек.` : 'Подтвердить телефон'}
          </button>
        )}
        {!isAuthorized && isOrderFields && (
          <Field
            name="code"
          >
            {({ input, meta }) => (
              <TextField
                {...input}
                meta={meta}
                disabled={!codeConfirmationSended}
                placeholder="Код подтверждения"
              />
            )}
          </Field>
        )}
        <Field
          name="email"
          validate={validators.is.email}
        >
          {({ input, meta }) => (
            <TextField
              {...input}
              meta={meta}
              placeholder="Email"
            />
          )}
        </Field>
        {/* {!isOrderFields && ( */}
        {/*  <Field */}
        {/*    name="shop_name" */}
        {/*    validate={validators.required} */}
        {/*  > */}
        {/*    {({ input, meta }) => ( */}
        {/*      <TextField */}
        {/*        {...input} */}
        {/*        meta={meta} */}
        {/*        placeholder="Название магазина" */}
        {/*      /> */}
        {/*    )} */}
        {/*  </Field> */}
        {/* )} */}
      </div>
    </div>
  );
};
